import { AfterViewChecked, Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { AutomatedClickThrough } from '../../shared/automated-click-through';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.page.html',
  styleUrls: ['./disclaimer.page.scss'],
})
export class DisclaimerPage extends AutomatedClickThrough implements OnInit, AfterViewChecked {

  private readonly modalCtrl = inject(ModalController);
  @ViewChild('acceptButton') acceptButtonRef: ElementRef;
  private readonly gtm = (<any>window).dataLayer || [];

  ngOnInit(): void {
    this.gtm.push({ event: 'custom_event', custom_event_name: 'view_ accept_disclaimer' });
  }

  automateClickThrough(): void {
    this.acceptDisclaimer();
  }

  acceptDisclaimer(): void {
    this.gtm.push({ event: 'custom_event', custom_event_name: 'click_ accept_disclaimer' });
    this.modalCtrl.dismiss();
  }

  ngAfterViewChecked(): void {
    //  Accept button has a tab -1 in the HTML to prevent ionic from auto scrolling to the button
    //  For accessibility reasons we reinstate the accept button into the tab order after the view has loaded
    setTimeout(() => {
      // any because typing doesn't know about the .el structure of the ion-button
      (<any>this.acceptButtonRef).el?.removeAttribute('tabindex');
    }, 1000);
  }

}
