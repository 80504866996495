import { Component, OnInit } from '@angular/core';

import { AppData } from 'src/app/data';
import { DispatcherService } from '../../services/dispatcher.service';
import { Fluids } from '../../models/fluids';
import { LocationService } from '../../services/location.service';
import { LoggingService } from '../../services/logging.service';
import { ModalController } from '@ionic/angular';
import { NotificationService } from '../../services/notification.service';
import { TranslateService } from "../../services/translate.service";
import { firstValueFrom } from 'rxjs';

enum Amounts {
  Odometer,
  GasGallons,
  GasCost
}

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.page.html',
  styleUrls: ['./maintenance.page.scss'],
})
export class MaintenancePage implements OnInit {

  private readonly gtm = (<any>window).dataLayer || [];

  amounts = {
    0 /*Odometer*/: null,
    1 /*GasGallons*/: null,
    2 /*GasCost*/: null,
  };

  AmountsEnum = Amounts;

  constructor(
    private data: AppData,
    private modalCtrl: ModalController,
    private dispatcherService: DispatcherService,
    private locationService: LocationService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private modalController: ModalController,
  ) {
  }


  async dismiss() {
    await this.modalController.dismiss();
  }

  async ngOnInit() {
    this.gtm.push({ event: 'custom_event', custom_event_name: 'view_ maintenance_dialog' });
    this.amounts[Amounts.Odometer] = this.data.gpsReport?.odometer || 0;
  }

  addAmountClicked(amountType: Amounts) {
    let val = this.amounts[amountType];
    if (!val) {
      val = 0;
    }
    val++;
    this.amounts[amountType] = val;
  }

  minusAmountClicked(amountType: Amounts) {
    let val = this.amounts[amountType];
    if (!val) {
      val = 1;
    }
    val--;
    this.amounts[amountType] = val;
  }

  validateIsNumber(amountType: Amounts) {
    const num = this.amounts[amountType];
    const isValid = !isNaN(parseFloat(num)) && isFinite(num);
    if (!isValid) {
      this.amounts[amountType] = 0;
    }
  }

  async submitGasReportClicked() {
    console.info(
      `Calling setOdometer in Maintenance.submitGasReportClicked ${this.amounts[Amounts.Odometer]}`);
    await this.locationService.setOdometer(this.amounts[Amounts.Odometer]);

    const report: Fluids = {
      gpsReport: this.data.gpsReport,
      totalCost: this.amounts[Amounts.GasCost],
      totalGallons: this.amounts[Amounts.GasGallons],
    };

    await this.dispatcherService.fluidsReport(report);
    this.notificationService.success(this.translateService.translate('LABEL.gasReportSent') + '.');
    await this.modalCtrl.dismiss();
  }
}
