import { Component, OnInit } from '@angular/core';
import { FairmaticService } from '../../services/fairmatic.service';
import { LoggingService } from '../../services/logging.service';
import { ModalController } from '@ionic/angular';
import { ZendriveStatus } from '../../services/plugin/zendrive-plugin.service';
import { map } from 'rxjs';

@Component({
  selector: 'app-zendrive-testing',
  templateUrl: './zendrive-testing.page.html',
  styleUrls: ['./zendrive-testing.page.scss'],
})
export class ZendriveTestingPage implements OnInit {

  private readonly gtm = (<any>window).dataLayer || [];

  public logEntries: string[] = [];
  private titleClickCount = 0;
  public enableAdminTools = false;
  public showFairmaticHistory = true;

  public readonly isZendriveSetup$ = this.fairmaticService.isZendriveSetup$;
  public readonly fairmaticDriverId$ = this.fairmaticService.fairmaticDriverId$;
  public readonly isValidDevice$ = this.fairmaticService.isValidDevice$;
  public readonly isValidCustomer$ = this.fairmaticService.isValidCustomer$;
  public readonly fairmaticHistory$ = this.fairmaticService.callHistory$
    .pipe(map(value => value.sort((a, b) => b.time?.valueOf() - a.time?.valueOf())));
  public readonly currentInsurancePeriod$ = this.fairmaticService.status$.
    pipe(map(status => this.formatPeriod(status?.insurancePeriod)));

  constructor(
    public loggingService: LoggingService,
    private modalController: ModalController,
    private fairmaticService: FairmaticService,
  ) { }

  ngOnInit(): void {
    this.gtm.push({ event: 'custom_event', custom_event_name: 'view_ zendrive_testing_dialog' });
  }

  titleClick() {
    if (++this.titleClickCount >= 6) {
      this.titleClickCount = 0;
      this.enableAdminTools = !this.enableAdminTools;
    }
  }

  private formatPeriod(period: number) {
    switch (period) {
      case 1: return `${period} - On Duty`;
      case 2: return `${period} - Accept Passenger Request (On the way)`;
      case 3: return `${period} - Passengers On Board`;
      case 0: return `${period} - Off Duty`;
      default: return `${period} - Unknown`;
    }
  }

  dismiss(): Promise<boolean> {
    return this.modalController.dismiss();
  }

  toggle(value: 'enableAdminTools' | 'showFairmaticHistory'): void {
    this[value] = !this[value];
  }

  async triggerEvent(action: 'setup' | 'status' | 'onTheWay' | 'goOnDuty'
    | 'pickupPassenger' | 'dropOffPassenger' | 'goOffDuty'): Promise<ZendriveStatus | void> {

    switch (action) {
      case 'setup': await this.fairmaticService.setupDriver(); break;
      case 'status': await this.fairmaticService.getActiveDriveInfo(); break;
      default: await this.fairmaticService.zendriveAction(action);
    }
  }
}
