import { Capacitor } from "@capacitor/core";
import { Deploy } from 'cordova-plugin-ionic/dist/ngx';
import { ISnapshotInfo } from "cordova-plugin-ionic/dist/ngx/IonicCordova";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
/**
 * This is an abstraction of the AppFlow Live Update api.
 * SEE: https://www.npmjs.com/package/cordova-plugin-ionic
 *      https://ionic.io/docs/appflow/deploy/api
 */
export class SnapshotManagementService {

  public get isWebPlatform() { return Capacitor.getPlatform() === "web"; }

  constructor(private deploy: Deploy) {}

  /**
   * Update the default configuration for the plugin on the current device. The new configuration will be
   * persisted across app close and binary updates.
   * See: https://ionic.io/docs/appflow/deploy/api#configure
   * @param config
   * @returns
   */
  public configure(config: Pro_CurrentConfig): Promise<void> {
    if (this.isWebPlatform) return Promise.resolve();
    return this.deploy.configure({
      appId: config.appId,
      channel: config.channel,
    });
  }

  /**
   * Get the current configuration for the plugin on the current device.
   * See: https://ionic.io/docs/appflow/deploy/api#getconfiguration
   * @returns the current configuration on this device
   */
  public async getConfiguration(): Promise<Pro_CurrentConfig> {
    if (this.isWebPlatform) return {
        appId: "0",
        channel: "NOT APPLICABLE",
      };

    const config = await this.deploy.getConfiguration();
    return {
      appId: config?.appId,
      channel: config?.channel,
      binaryVersionCode: config?.binaryVersionCode,
      binaryVersionName: config?.binaryVersionName,
      disabled: config?.disabled,
      host: config?.host,
      updateMethod: config?.updateMethod,
      currentBuildId: config?.currentBuildId,
      currentVersionId: config?.currentVersionId,
    };
  }

  /**
   * Get a list of the snapshots available on the device.
   * REMEMBER: For a fresh apk install, this will return nothing. It doesn't go to the server
   * See: https://ionic.io/docs/appflow/deploy/api#getavailableversions
   * @returns a list of available versions that have previously been downloaded.
   */
  public async getAvailableVersions(): Promise<Pro_SnapshotInfo[]>  {
    if (this.isWebPlatform) return [];
    const versions = await this.deploy.getAvailableVersions();
    const mappedVersions: Pro_SnapshotInfo[] = versions.filter(x => !!x)
      .map(ss => this.mapSnapshotInfo(ss));
    if (mappedVersions) return mappedVersions;
    return [];
  }

  /**
   * Get info about the currently deployed update or undefined if none are applied.
   * REMEMBER: For a fresh apk install, this will return undefined.
   * See: https://ionic.io/docs/appflow/deploy/api#getCurrentVersion
   * @returns the current version this device has installed through appflow
   */
  public async getCurrentVersion(): Promise<Pro_SnapshotInfo | undefined> {
    if (this.isWebPlatform) return undefined;
    const snapshot = await this.deploy.getCurrentVersion();
    return this.mapSnapshotInfo(snapshot);
  }

  /**
   * Check for available updates for the currently configured app id and channel. (this goes out to the server)
   * See: https://ionic.io/docs/appflow/deploy/api#checkforupdate
   * @returns the available version on the appflow server.
   */
  public async checkForUpdate(): Promise<{snapshotId: (string|undefined); buildId: (string|undefined);} | undefined> {
    if (this.isWebPlatform) return Promise.resolve(undefined);
    const snapshot = await this.deploy.checkForUpdate();
    if (!snapshot.available) return undefined;
    return {
      snapshotId: snapshot.snapshot,
      buildId: snapshot.build,
    };
  }

  /**
   * Check for an update, download it, and apply it in one step.
   * See: https://ionic.io/docs/appflow/deploy/api#sync
   * @param method (optional) auto or background.
   * @param progressCallback a callback that will give you access to the current percentage complete for the update download, as it's downloading.
   * @returns A Promise that returns the new snapshot info.
   */
  public async sync(
    method?: "background" | "auto",
    progressCallback?: (pct?: number) => void,
  ): Promise<Pro_SnapshotInfo | undefined> {
    // return Promise.resolve(undefined);
    if (this.isWebPlatform) return undefined;
    const snapshot = await this.deploy.sync({ updateMethod: method }, progressCallback);
    return this.mapSnapshotInfo(snapshot);
  }

  private mapSnapshotInfo(snapshotInfo?: ISnapshotInfo | undefined): Pro_SnapshotInfo | undefined {
    if (!snapshotInfo) return undefined;
    return {
      channel: snapshotInfo.channel,
      buildId: snapshotInfo.buildId,
      versionId: snapshotInfo.versionId,
      binaryVersionCode: snapshotInfo.binaryVersionCode,
      binaryVersionName: snapshotInfo.binaryVersionName,
    };
  }
}


export interface Pro_CurrentConfig {
  /**
   * The [Ionic Pro](https://ionicframework.com/docs/pro/) app id.
   */
  appId: string;
  /**
   * The [channel](https://ionicframework.com/docs/pro/deploy/channels) that the plugin should listen for updates on.
   */
  channel: string;
  /**
   * The binary version of the native bundle versionName on Android or CFBundleShortVersionString on iOS
   */
  binaryVersionName?: string;
  /**
   * The build version code of the native bundle versionCode on Android or CFBundleVersion on iOS
   */
  binaryVersionCode?: string;
  /**
   * Whether the user disabled deploy updates or not.
   */
  disabled?: boolean;
  /**
   * The host API the plugin is configured to check for updates from.
   */
  host?: string;
  /**
   * The currently configured updateMethod for the plugin.
   */
  updateMethod?: 'none' | 'auto' | 'background';
  /**
   * The id of the currently applied updated or undefined if none is applied.
   */
  currentVersionId?: string;
  /**
   * The id of the currently applied build or undefined if none is applied.
   */
  currentBuildId?: string;
}

export interface Pro_SnapshotInfo {
  /**
   * The id for the snapshot.
   */
  versionId: string;
  /**
   * The id for the snapshot.
   */
  buildId: string;
  /**
   * The channel that the snapshot was downloaded for..
   */
  channel: string;

  /**
   * The binary version name the snapshot was downloaded for.
   * The versionName on Android or CFBundleShortVersionString on iOS this is the end user readable version listed on the stores.
   */
  binaryVersionName: string;
  /**
   * The binary version build code the snapshot was downloaded for.
   * The versionCode on Android or CFBundleVersion on iOS this should be changed every time you do a new build debug or otherwise.
   */
  binaryVersionCode: string;
}
