<ion-card *ngIf="flagStop && flagStop.enablePerform && !flagStop.performed">
  <ion-card-header>
    <div>
      <ion-card-title class="title">
        <div [ngClass]="{ 'late-stop' : flagStop.inThePast }">
          {{flagStop.arrivalTime | amDateFormat:'HH:mm'}}
          <span *ngIf="flagStop.arrivalTime != flagStop.departureTime">
            {{ 'LABEL.to' | translate }} {{ flagStop.departureTime | amDateFormat:'HH:mm' }}
          </span>
        </div>

        <ng-container *ngIf="flagStop.timePoint">
          <ion-icon name='time'></ion-icon>
          <span>{{ 'LABEL.timePoint' | translate }}</span>
        </ng-container>

        <span *ngIf="!flagStop.timePoint">
          {{ 'LABEL.stop' | translate }}
        </span>

      </ion-card-title>
      <ion-card-subtitle>
        <span class="stop-time-remaining">{{flagStop.timeToStop}}</span>
      </ion-card-subtitle>
    </div>

  </ion-card-header>

  <ion-card-content>
    <ion-row>
      <ion-col size="12" sizeMd="6">
        <address>
          <strong>{{ flagStop.location }}</strong>
          <br>{{ flagStop.name || 'Blank Headsign' }} ({{ flagStop.direction }})
        </address>
      </ion-col>

      <ion-col size="12" sizeMd="6" *ngIf=" !data.currentRouteIsPreview" class="button-list">
        <ion-button
          type="button"
          expand="block"
          color="secondary"
          class="notify-button"
          *ngIf="data.route.allowQuickPerform"
          (click)="quickPerform()">
          <ion-icon name='bus' slot="start"></ion-icon>
          {{ 'ACTION.quickPerform' | translate }}
        </ion-button>

        <ion-button
          type="button"
          expand="block"
          color="primary"
          class="notify-button"
          (click)="startFlagStop()">
          <ion-icon name='locate' slot="start"></ion-icon>
          {{ 'ACTION.arrive' | translate }}
        </ion-button>

        <ion-button
          type="button"
          fill="outline"
          expand="block"
          color="primary"
          [disabled]="!flagStop.latitude"
          (click)="navigateToFlagStop($event)">
          <ion-icon name='navigate' slot="start"></ion-icon>
          {{ 'ACTION.navigate' | translate }}
        </ion-button>

        <ion-button
          type="button"
          [disabled]="!flagStop.latitude"
          expand="block"
          fill="outline"
          color="medium"
          (click)="previewLocation($event);"
          *ngIf="!isIos"
        >
          <ion-icon name='map' slot="start"></ion-icon>
          <span class="hidden-md">{{ 'LABEL.seeOn' | translate }}&nbsp;</span>
          {{ 'LABEL.map' | translate }}
        </ion-button>
      </ion-col>

    </ion-row>
  </ion-card-content>
</ion-card>
