import { ErrorHandler, Injectable } from '@angular/core';

import { FirebaseCrashlytics } from '@capacitor-community/firebase-crashlytics';
import { LoggingService } from './logging.service';
import { NotificationService } from './notification.service';
import { Platform } from '@ionic/angular';

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {

  constructor(
    public loggingService: LoggingService,
    public notificationService: NotificationService,
    public platform: Platform,
  ) { }

  handleError(error: any): void {
    //  just log it to the console and TrackJS will pick it up
    console.error(error);
    try {
      if (this.platform.is('android') || this.platform.is('ios')) {
        FirebaseCrashlytics.addLogMessage(error);
      }
    } catch (err) {
      console.log(error);
    }
  }

}
