<ion-card (click)="showJobDetails($event)" class="job-card" [class.pickup]="job.jobType == 1">
  <ion-card-header class="job-card-header">
    <div>
      <ion-card-title class="title">
        <span class="top-left-top stop-time" [ngClass]="{ 'late-stop' : job.inThePast }">
          {{ !job.scheduledTime ? '00:00' : job.scheduledTime | amDateFormat:'HH:mm' }}
        </span>
        <span class="job-type" [class.pickup]="job.jobType == 1">
          {{ job.jobType == 1 ? ('LABEL.pickup' | translate) : ('ACTION.dropOff' | translate) }}
          {{ handleName(job.riderFirstName, job.riderLastName) }}
        </span>
      </ion-card-title>

      <ion-card-subtitle>
        <span class="stop-time-remaining">{{job.timeToStop}}</span>
      </ion-card-subtitle>

      <div class="badges">
        <ion-badge
          *ngIf="job.willCall && job.jobType === 1"
          class="assistance-badge"
          color="warning">
          {{ job.willCallReady ? '' : ('LABEL.notReady' | translate) }}
          {{ 'LABEL.willCall' | translate }}
          {{ job.willCallReady ? ('LABEL.ready' | translate) : '' }}
        </ion-badge>

        <ion-icon
          *ngIf="job.riderImageId"
          class="rider-image-exists"
          color="medium"
          name="person-circle-outline"
          >
        </ion-icon>

        <ion-icon
          *ngIf="job.rideComments || job.riderComments"
          class="ride-comments"
          color="medium"
          name="chatbubbles"></ion-icon>

        <ion-badge
          color="medium"
          class="special-assistance-badge"
          *ngFor="let specialAssistance of job.specialAssistances">
          {{specialAssistance}}
        </ion-badge>
      </div>
    </div>

    <div class="mobility-icons">
      <ion-img class="mobility-icon" [src]="'assets/img/Ambulatory.png'" *ngIf="job.ambulatorySeats && !job.liftRequired" [alt]="'MOBILITY.ambulatory' | translate"></ion-img>
      <ion-img class="mobility-icon" [src]="'assets/img/Wheelchair.png'" *ngIf="job.wheelchairSeats" [alt]="'MOBILITY.wheelchairSeats' | translate"></ion-img>
      <ion-img class="mobility-icon" [src]="'assets/img/Stretcher.png'" *ngIf="job.stretcherCount" [alt]="'MOBILITY.stretcher' | translate"></ion-img>
      <ion-img class="mobility-icon" [src]="'assets/img/Lift.png'" *ngIf="job.liftRequired" [alt]="'MOBILITY.lift' | translate"></ion-img>
    </div>
  </ion-card-header>

  <ion-card-content>

    <ion-row>
      <ion-col size="12" sizeMd="6" class="job-details">
        <div>
          <address class="address" *ngIf="job.location.state">
            <strong>{{ job.location.name }}</strong> - {{ job.location.address }} {{ job.location.address2 }}
            <br>{{ job.location.city }}, {{ job.location.state }}, {{ job.location.zipCode }}
            <ng-container *ngIf="job.jobType == 1 ? !!job.pickupPhone : !!job.dropoffPhone">
              <br>
              <a href="tel:+1{{ job.jobType == 1 ? job.pickupPhone : job.dropoffPhone }}">
                {{ job.jobType == 1 ? job.pickupPhone : job.dropoffPhone }}
              </a>
            </ng-container>
          </address>

          <div *ngIf="!isDetailView" class="detail-note">
            <ion-icon name='expand'></ion-icon>
            {{ 'LABEL.tapForDetails' | translate }}
          </div>
        </div>
      </ion-col>

      <!--right side with buttons-->
      <ion-col size="12" sizeMd="6" class="button-list">
        <ng-container *ngIf="job.jobEnabled && !data.currentRouteIsPreview && job.isAtNextLocation">
          <ion-button
            type="button"
            *ngIf="!job.arriveTime && !job.flagStopId && !onTheWayCompleted"
            expand="block"
            color="primary"
            (click)="onTheWay($event)"
          >
            <ion-icon name="send" slot="start"></ion-icon>
            <span>{{ 'ACTION.onTheWay' | translate }}</span>
          </ion-button>

          <ion-button
            type="button"
            *ngIf="!job.arriveTime && !job.flagStopId && onTheWayCompleted"
            expand="block"
            color="primary"
            (click)="arriveJob($event)"
          >
            <ion-icon name="locate" slot="start"></ion-icon>
            <span>{{ 'ACTION.arrive' | translate }}</span>
            <span class="hidden-lg">&nbsp;{{ job.jobType == 1 ? ('LABEL.pickup' | translate) : ('ACTION.dropOff' | translate) }}</span>
          </ion-button>

          <ion-button
            type="button"
            *ngIf="job.jobType == 1 && job.arriveTime"
            expand="block"
            color="success"
            (click)="navigateToPickupPerform($event)"
          >
            <ion-icon name="checkmark-circle" slot="start"></ion-icon>
            <span>{{ 'ACTION.onboard' | translate }}</span>
          </ion-button>

          <ion-button
            type="button"
            *ngIf="job.jobType == 1 && job.arriveTime"
            expand="block"
            color="danger"
            (click)="navigateToNoShowPerform($event)"
          >
            <ion-icon name="close-circle" slot="start"></ion-icon>
            <span>{{ 'ACTION.noShow' | translate }}</span>
          </ion-button>

          <ion-button
            type="button"
            *ngIf="job.jobType == 2 && (job.arriveTime || job.flagStopId)"
            expand="block"
            color="primary"
            (click)="navigateToDropoffPerform($event)"
          >
            <ion-icon name="trending-down" slot="start"></ion-icon>
            <span>{{ 'ACTION.dropOff' | translate }}</span>
          </ion-button>
        </ng-container>

        <ion-button
          type="button"
          fill="outline"
          expand="block"
          color="primary"
          (click)="navigateToDestination($event)"
          *ngIf="(job.jobEnabled && !data.currentRouteIsPreview) && job.isAtNextLocation"
        >
          <ion-icon name="navigate" slot="start"></ion-icon>
          <span>{{ 'LABEL.navigate' | translate }}</span>
        </ion-button>

          <ion-button
            type="button"
            fill="outline"
            color="medium"
            (click)="previewLocation($event);"
            *ngIf="data.currentRouteIsPreview || !isIos"
          >
            <ion-icon name='map' slot="start"></ion-icon>
            <span class="hidden-md">{{ 'LABEL.seeOn' | translate }}&nbsp;</span>
            <span>{{ 'LABEL.map' | translate }}</span>
          </ion-button>

          <ion-button
            type="button"
            fill="outline"
            expand="block"
            color="medium"
            *ngIf="(job.jobEnabled && !data.currentRouteIsPreview) && job.isAtNextLocation && job.jobType == 1 && !data.currentRouteIsPreview && showNotify"
            (click)="notifyPassenger($event)"
          >
            <ion-icon name="call" slot="start"></ion-icon>
            <span>{{ 'LABEL.notify' | translate }}</span>
          </ion-button>

      </ion-col>
    </ion-row>
  </ion-card-content>

</ion-card>
