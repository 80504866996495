import { ChangeDetectorRef, Component, Input, OnInit, inject } from '@angular/core';
import { InspectionItem, InspectionResultEnum } from '../../models/inspection';
import { LoadingController, ModalController } from '@ionic/angular';

import { AutomatedClickThrough } from '../../shared/automated-click-through';
import { NonServicePeriod } from '../../models/non-service-period';
import { TranslateService } from '../../services/translate.service';

@Component({
  selector: 'app-inspection',
  templateUrl: './inspection.page.html',
  styleUrls: ['./inspection.page.scss'],
})
export class InspectionPage extends AutomatedClickThrough implements OnInit {

  @Input() onComplete: (success: boolean, inspectionItems: InspectionItem[]) => void;
  @Input() nonServicePeriod: NonServicePeriod;

  public state: 'inspection' | 'criticalWarning' = 'inspection';

  private readonly loadingCtrl = inject(LoadingController);
  private readonly modalCtrl = inject(ModalController);
  private readonly translateService = inject(TranslateService);

  private readonly gtm = (<any>window).dataLayer || [];

  public canSubmit = false;

  // Making the enum available to the template
  InspectionResultEnum = InspectionResultEnum;
  inspectionItems: InspectionItem[] = [];
  inspectionGroups: {
    serviceCategory: string,
    inspectionItems: InspectionItem[]
  }[] = [];

  ngOnInit() {
    this.gtm.push({ event: 'custom_event', custom_event_name: 'view_ view_inspection' });
    this.inspectionItems = this.nonServicePeriod.inspectionItems;

    const inspectionGroupMap: { [serviceCategory: string]: InspectionItem[] } = {};
    for (const inspectionItem of this.inspectionItems) {
      inspectionGroupMap[inspectionItem.serviceCategory] = inspectionGroupMap[inspectionItem.serviceCategory] || [];
      inspectionGroupMap[inspectionItem.serviceCategory].push(inspectionItem);
    }

    for (const key in inspectionGroupMap) { // tslint:disable-line
      this.inspectionGroups.push({
        serviceCategory: key,
        inspectionItems: inspectionGroupMap[key],
      });
      inspectionGroupMap[key].sort((a, b) => {
        const aLowerCase = a.description.toLowerCase();
        const bLowerCase = b.description.toLowerCase();
        if (aLowerCase > bLowerCase) {
          return 1;
        } else if (aLowerCase < bLowerCase) {
          return -1;
        }
        return 0;
      });
    }

    this.inspectionGroups.sort((a, b) => {
      const aLowerCase = a.serviceCategory.toLowerCase();
      const bLowerCase = b.serviceCategory.toLowerCase();
      if (aLowerCase > bLowerCase) {
        return 1;
      } else if (aLowerCase < bLowerCase) {
        return -1;
      }
      return 0;
    });
  }

  automateClickThrough(): void {
    this.submitInspection();
  }

  public inspectionValueClicked(event: any, inspection: InspectionItem): void {
    inspection.result = Number(event.detail.value);
    this.canSubmit = this.isInspectionCompleted();
  }

  public inspectionValueChanged(inspection: InspectionItem, value: string): void {
    inspection.textResult = value;
    this.canSubmit = this.isInspectionCompleted();
  }

  private isInspectionCompleted(): boolean {
    for (const inspection of this.inspectionItems) {
      if (!inspection.result && (!inspection.textResult || inspection.textResult.length === 0)) {
        return false;
      }
    }
    return true;
  }


  public async submitInspection(): Promise<boolean> {
    const loading = await this.loadingCtrl.create({
      spinner: 'crescent',
      message: this.translateService.translate('LABEL.finishingInsp'),
      backdropDismiss: false,
    });
    await loading.present();
    const failuresExist = this.inspectionItems.some(item => item.isCritical && item.result === InspectionResultEnum.ServiceCritical);

    if (failuresExist) {
      this.state = 'criticalWarning';
      console.log(failuresExist, this.state);
      loading.dismiss();
      return false;
    }

    this.onComplete((!failuresExist), this.inspectionItems);
    loading.dismiss();
    return this.submit(failuresExist);
  }

  public cancelCriticalWarning(): void {

    this.state = 'inspection';
  }

  public submit(failuresExist: boolean): Promise<boolean> {
    this.onComplete((!failuresExist), this.inspectionItems);
    return this.modalCtrl.dismiss({
      success: !failuresExist,
      inspectionItems: this.inspectionItems,
    });
  }

  public getFailures(): InspectionItem[] {
    return this.inspectionItems.filter(item => item.isCritical && item.result === InspectionResultEnum.ServiceCritical);
  }
}
