import {Component, Input, OnInit} from '@angular/core';
import {PassDetails, isCashPass, isFlexPass, isSiucPass} from '../../models/pass';

import {DecimalPipe} from '@angular/common';
import {FlagStopPassScan} from '../../models/flag-stop';
import {ModalController} from '@ionic/angular';
import {RiderTypePerform} from '../../models/rider-type-perform';
import {Router} from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-flag-stop-pass-scan',
    templateUrl: './flag-stop-pass-scan.page.html',
    styleUrls: ['./flag-stop-pass-scan.page.scss'],
})
export class FlagStopPassScanPage implements OnInit {
    cashCollected = 0;

    private titleMessage: string;
    private subtitleMessage: string;

    @Input()
    public passInfo: PassDetails;
    @Input()
    public riderTypePerform: RiderTypePerform;
    @Input()
    public passId: string;

    private readonly gtm = (<any>window).dataLayer || [];

    constructor(private router: Router,
                private modalController: ModalController,
                private numberPipe: DecimalPipe) {

        // do this to default the cash collected to the fare owed
        // this.cashCollected = this.riderTypePerform.defaultFare;
    }

    ngOnInit() {
        this.gtm.push({ event: 'custom_event', custom_event_name: 'view_ flag_stop_pass_scan' });
        this.populateTitles();
    }

    async dismiss() {
        await this.modalController.dismiss();
    }

    isCashPass(passType: PassDetails['passType']): boolean { return isCashPass(passType); }

    populateTitles() {
        this.subtitleMessage = 'Do you want to provide this ride?';
        if (!this.passInfo) {
            this.titleMessage = `${environment.appName} is offline and the pass cannot be verified.`;
        } else if (this.passInfo.integrationFailure) {
            this.titleMessage = `${environment.appName} is online but the university\'s database is offline and the pass cannot be verified.`;
        } else if (!this.passInfo.passIdIsValid) {
            this.titleMessage = 'Pass is not valid.';
            this.subtitleMessage = 'You cannot provide this ride using this pass.';
        } else if (!this.passInfo.isActive) {
            this.titleMessage = 'Pass is not active.';
        } else if (isSiucPass(this.passInfo.passType) && !this.passInfo.siucDawgTagIsValid) {
            this.titleMessage = 'This SIUC student dawg tag cannot be found in the university database.';
        } else if (isSiucPass(this.passInfo.passType) && !this.passInfo.siucStudentIsActive) {
            this.titleMessage = 'This SIUC student is not active in the university database.';
        } else if (isSiucPass(this.passInfo.passType) && !this.passInfo.siucMassTransitEnabled) {
            this.titleMessage = 'This SIUC student is not eligible for mass transit.';
        } else if (isCashPass(this.passInfo.passType) && (this.passInfo.remainingBalance || 0) <= 0) {
            this.titleMessage = 'This pass has no funds remaining.';
            this.subtitleMessage = `You will need to collect`
                    + ` $${this.numberPipe.transform(this.passInfo.debitAmoutForRide || 0, '.2-2')} in cash or deny the ride.`;
        } else if (isCashPass(this.passInfo.passType) && (this.passInfo.remainingBalance || 0) < this.passInfo.debitAmoutForRide) {
            this.titleMessage = `This pass has $${this.numberPipe.transform(this.passInfo.remainingBalance || 0, '.2-2')} `
                    + `remaining which is insufficient to cover the fare of`
                    + ` $${this.numberPipe.transform(this.passInfo.debitAmoutForRide || 0, '.2-2')}.`;
            this.subtitleMessage = `You will need to collect`
                    + ` $${this.numberPipe.transform(this.passInfo.debitAmoutForRide - this.passInfo.remainingBalance, '.2-2')} `
                    + `in cash or deny the ride.`;
        } else if (isFlexPass(this.passInfo.passType) && this.passInfo.isNotYetEffective) {
            this.titleMessage = 'This pass is not yet effective.';
        } else if (isFlexPass(this.passInfo.passType) && this.passInfo.isExpired) {
            this.titleMessage = 'This pass is expired.';
        } else if (isFlexPass(this.passInfo.passType) && this.passInfo.remainingRides <= 0) {
            this.titleMessage = 'This pass is out of rides.';
        } else if (isFlexPass(this.passInfo.passType) && this.passInfo.isExpired) {
            this.titleMessage = 'Pass is expired.';
        } else if (isFlexPass(this.passInfo.passType) && this.passInfo.debitAmoutForRide > 0) {
            this.titleMessage = `This pass is valid but does not cover the fare of`
                    + ` $${this.numberPipe.transform(this.passInfo.debitAmoutForRide || 0, '.2-2')}.`;
            this.subtitleMessage = `You will need to collect`
                    + ` $${this.numberPipe.transform(this.passInfo.debitAmoutForRide || 0, '.2-2')} in cash or deny the ride.`;
        } else {
            // the pass is valid and sufficient
            this.subtitleMessage = '';
            if ((this.passInfo.debitAmoutForRide || 0) === 0) {
                this.titleMessage = 'This pass is valid and sufficient.';
                this.subtitleMessage = 'No fare is due.';
            } else {
                this.titleMessage = `This pass is valid and sufficient to cover the fare of`
                    + ` $${this.numberPipe.transform(this.passInfo.debitAmoutForRide || 0, '.2-2')}.`;
            }
        }
    }

    async denyRideClicked() {
        this.gtm.push({ event: 'custom_event', custom_event_name: 'click_ deny_ride' });
        await this.modalController.dismiss();
    }

    async provideRideClicked() {
      this.gtm.push({ event: 'custom_event', custom_event_name: 'click_ provide_ride' });
      this.riderTypePerform.scannedPasses.push({
          passId: this.passId,
          cashFareCollected: this.cashCollected,
          isPickup: true,
      } as FlagStopPassScan);

      await this.modalController.dismiss();
    }
}
