import { Component, Input, OnInit, inject } from '@angular/core';

import { AutomatedClickThrough } from '../../shared/automated-click-through';
import { LocationService } from '../../services/location.service';
import { ModalController } from '@ionic/angular';
import { RouteSummary } from '../../models/route-summary';

@Component({
  selector: 'app-odometer',
  templateUrl: './odometer.page.html',
  styleUrls: ['./odometer.page.scss'],
})
export class OdometerPage extends AutomatedClickThrough implements OnInit {

  private readonly locationService = inject(LocationService);
  private readonly modalController = inject(ModalController);
  private readonly gtm = (<any>window).dataLayer || [];

  @Input() routeSummary: RouteSummary;
  tempOdometer: number;

  async ngOnInit() {
    this.gtm.push({ event: 'custom_event', custom_event_name: 'view_ odometer_page' });
    this.tempOdometer = Math.round(this.routeSummary.expectedRouteStartOdometer);
  }

  automateClickThrough() {
    this.submitOdometer().then();
  }

  async submitOdometer() {
    console.info(`Calling setOdometer in OdometerPage.submitOdometer ${this.tempOdometer}`);
    await this.locationService.setOdometer(this.tempOdometer);
    console.info('close');
    await this.modalController.dismiss({ odometerSubmitted: true });
  }

  async dismissModal() {
    await this.modalController.dismiss({ odometerSubmitted: false });
  }
}
