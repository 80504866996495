import { AppData, DEFAULT_DISPATCH_URL, DEPRECATED_LEGACY_DISPATCHER_URL_1, DEPRECATED_LEGACY_DISPATCHER_URL_2, LOCAL_SETTINGS_KEY } from '../data';
import { Injectable, inject } from '@angular/core';
import { LocalSettings, defaultLocalSettings } from '../models/local-settings';

import { StorageService } from './storage.service';
import { TrackJS } from 'trackjs';
import { UUID } from 'angular2-uuid';

@Injectable({
  providedIn: 'root',
})
export class SettingsService extends StorageService<LocalSettings> {

  private readonly data = inject(AppData);
  private readonly gtm = (<any>window).dataLayer || [];

  protected getStorageKey() { return LOCAL_SETTINGS_KEY; }

  async saveSettings(updates: Partial<LocalSettings>): Promise<LocalSettings> {
    const settings = this.data.localSettings;
    const payload = { ...settings, ...updates };
    for (const key in payload) { TrackJS.addMetadata(key, payload[key]); }

    await this.saveObjectInStorage(payload);
    this.gtm.push({
      customer_name: payload?.customerName,
      customer_id: payload?.customerId,
      uuid: payload?.uuid,
      environment_name: payload?.environment,
    });
    this.data.set('localSettings', payload);
    return payload;
  }

  public async getLocalSettings(): Promise<LocalSettings> {
    let localSettings = await this.getObjectFromStorage()
      || this.data.localSettings
      || defaultLocalSettings;

    localSettings = await this.localSettingsSetup(localSettings);
    await this.saveSettings({ ...localSettings, automateClickThrough: false });

    return localSettings;
  }

  private async localSettingsSetup(localSettings: LocalSettings): Promise<LocalSettings> {
    if (localSettings.uuid.length < 36) {
      await this.saveSettings({ uuid: UUID.UUID() });
    }

    // overwrite deprecated old urls (v2 and core)
    if (localSettings.dispatcherUrl) {
      const url = localSettings.dispatcherUrl.toLowerCase();
      if (
        url === DEPRECATED_LEGACY_DISPATCHER_URL_1.toLowerCase()
        || url === DEPRECATED_LEGACY_DISPATCHER_URL_2.toLowerCase()
      ) {
        console.warn('Overwriting deprecated dispatcher url', localSettings.dispatcherUrl);
        localSettings.dispatcherUrl = DEFAULT_DISPATCH_URL;
      }
    }
    if (!localSettings.dispatcherUrl) { localSettings.dispatcherUrl = DEFAULT_DISPATCH_URL; }
    return localSettings;
  }

}
