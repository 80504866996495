import { BarcodeScanner as CordovaBarcodeScanner } from "@awesome-cordova-plugins/barcode-scanner/ngx";
import { EMPTY, Observable } from "rxjs";

import { AlertService } from "../alert.service";
import { Camera } from "@capacitor/camera";
// capacitor plugins
import { Capacitor } from "@capacitor/core";
import { BackgroundMode as CordovaBackgroundMode } from "@awesome-cordova-plugins/background-mode/ngx";
// cordova plugins
import { Diagnostic as CordovaDiagnostic } from "@awesome-cordova-plugins/diagnostic/ngx";
import { LaunchNavigator as CordovaLaunchNavigator } from "@awesome-cordova-plugins/launch-navigator/ngx";
import { PowerManagement as CordovaPowerManagement } from "@awesome-cordova-plugins/power-management/ngx";
import { Device } from "@capacitor/device";
// custom capacitor plugin
import { ForegroundService } from '@capawesome-team/capacitor-android-foreground-service';
import { Injectable } from "@angular/core";
import { KeepAwake } from '@capacitor-community/keep-awake';
import { LocalNotifications } from "@capacitor/local-notifications";
import { Network } from "@capacitor/network";
import { Platform } from "@ionic/angular";
import { SplashScreen } from '@capacitor/splash-screen';
import { WebPluginFunctionalityService } from "./web-plugin-functionality.service";
import { environment } from "src/environments/environment";
import { TranslateService } from "../translate.service";

@Injectable({
  providedIn: "root",
})
export class NativePluginService {
  private readonly gtm = (<any>window).dataLayer || [];

  private _cachedEnvironmentInfo: IEnvironmentInformation | null = null;
  private _isBackgroundModeConfigured = false;
  deviceInfo = null;
  // appInfo = null;

  constructor(
    protected cordovaDiagnostic: CordovaDiagnostic,
    protected cordovaBarcodeScanner: CordovaBarcodeScanner,
    protected cordovaLaunchNavigator: CordovaLaunchNavigator,
    protected cordovaBackgroundMode: CordovaBackgroundMode,
    protected cordovaPowerManagement: CordovaPowerManagement,
    protected webSvc: WebPluginFunctionalityService,
    protected alertService: AlertService,
    protected platform: Platform,
    protected translate: TranslateService,
  ) { }

  async init() {
    if (Capacitor.isNativePlatform()) {
      console.info('NativePluginService initializing.');
      await this.platform.ready();

      this.platform.backButton.subscribeWithPriority(9999, () => {
        console.info('back button disabled with subscribeWithPriority.');
      });

      document.addEventListener('backbutton', function (event) {
        event.preventDefault();
        event.stopPropagation();
        console.info('back button disabled with addEventListener(backbutton).');
      }, false);

      if (this.isAndroid) {
        await this.setWakeLock();
      } else {
        await KeepAwake.keepAwake();
      }

      SplashScreen.hide();
      console.info('NativePluginService initialized.');
    }

  }

  disableBackButton() {

  }

  /** Prevent screen from going to sleep */
  async setWakeLock(): Promise<void> {
    try {
      await this.acquireWakeLock();
      console.debug('Wakelock acquired');
    } catch (err) {
      console.warn('Wakelock not acquired');
    }
  }


  async checkForAllRequiredPermissions() {
    await this.alertService.initialize();
  }

  /**
   * (Android only) Check the ManageOverlayPermission to see if bring-to-front is allowed.
   * @returns a boolean promise. If the permission is set, return true.
   */
  public async checkPermissionsForMoveToForeground(): Promise<boolean> {
    if (!this.isAndroid) return false;
    return new Promise<boolean>(async (resolve, reject) => {
      let permission = await ForegroundService.checkManageOverlayPermission();
      if (!permission.granted) {
        await this.alertService.presentAlertAsync({
          header: `Please allow ${environment.appName} to draw over other apps.`,
          //Must include: “background” / “when the app is closed” / “always in use” / “when the app is not in use”.
          message: "This app needs permission to draw over other apps in order to function properly.",
          buttons: [{ text: 'Ok' }],
        }, async () => {
          try {
            permission = await ForegroundService.requestManageOverlayPermission();
            resolve(permission.granted);
          } catch (x) {
            // if there's an error, return false, saying that you don't have permission.. I think this is okay.
            console.error(x);
            resolve(false);
          }
        });
      } else {
        resolve(permission.granted);
      }
    });
  }

  /**
   * Check to see if the permission to use the camera (for reading barcodes) has been given.
   * @returns a boolean promise. If the permission is set, return true.
   */
  public async checkPermissionsForBarcodeScanner(): Promise<boolean> {
    if (!Capacitor.isNativePlatform()) return Promise.resolve(false);
    return new Promise<boolean>(async resolve => {
      let permission = await Camera.checkPermissions();
      if (permission.camera === "denied" || permission.camera === "prompt-with-rationale" || permission.camera === "prompt") {
        permission = await Camera.requestPermissions({ permissions: ['camera'] });
        resolve(permission.camera !== "denied");
      }
      resolve(true);
    });
  }

  /**
   * (Android only) Move this app to the foreground, if permission has been granted.
   * @returns true if the user has permission to move to the foreground.
   */
  public async moveToForegroundUsingCapacitorPlugin(): Promise<boolean> {
    if (!this.isAndroid) return false;
    const permissionGranted = await this.checkPermissionsForMoveToForeground();

    if (permissionGranted) await ForegroundService.moveToForeground();
    return permissionGranted;
  }

  /**
   * (Android only) Stop the foreground service (if started already)
   * @returns an empty promise.
   */
  public async stopForegroundService(): Promise<void> {
    if (!this.isAndroid) return Promise.resolve();
    return await ForegroundService.stopForegroundService();
  }

  /**
   * (Android only) IF using a foreground service, all related events can be handled with this event listener
   * @param listener A handler for any notifications coming from the foreground service  (like a button click?)
   * @returns an empty promise.
   */
  public async addListenerForForegroundServiceNotifications(
    listener: (n: any) => void,
  ): Promise<void> {
    if (!this.isAndroid) return;
    const p = await ForegroundService.addListener('buttonClicked',
      (n) => {
        listener(n);
      },
    );
  }

  /**
   * (Android only) If using a foreground service, remove any listeners currently active with the plugin.
   * @returns an empty promise.
   */
  public async removeAllListenersForForegroundServiceNotifications(): Promise<void> {
    if (!this.isAndroid) return;
    await ForegroundService.removeAllListeners();
  }


  /**
   * Check permissions for local notifications.
   * @returns the permission status for local notifications (in a promise)
   */
  public async checkPermissionsForLocalNotifications(): Promise<PermissionStatusEnum> {
    const p = await LocalNotifications.checkPermissions();
    return this.permissionStateToEnum(p.display || "prompt");
  }

  /**
   * Request permission to send local notifications.
   * @returns the permission status for local notifications (in a promise)
   */
  public async requestPermissionForLocalNotifications(): Promise<PermissionStatusEnum> {
    const p = await LocalNotifications.requestPermissions();
    return this.permissionStateToEnum(p.display || "denied");
  }

  /**
   * If using a local notifications, all related events can be handled with this event listener
   * @param listener A handler for any notifications that are clicked
   * @returns an empty promise.
   */
  public async addListenerForReceivedLocalNotifications(
    listener: (n: any) => void,
  ): Promise<void> {
    const listenerHandle = await LocalNotifications.addListener(
      "localNotificationReceived",
      (n) => {
        listener(n);
      },
    );
  }

  /**
   * If using local notifications, remove any listeners currently active with the plugin.
   * @returns an empty promise.
   */
  public async removeAllListenersForLocalNotifications(): Promise<void> {
    await LocalNotifications.removeAllListeners();
  }

  /**
   * Send a local notification. Local notifications are scheduled, so this function adds optional delay seconds to the current time and schedules the notification.
   * @param id An id for this notification. (integer - not too big)
   * @param title The title of the notification
   * @param body The body text of the notification when expanded
   * @param delayInSeconds The delay before sending the notificaiton (it has to be scheduled - 0 is the default here)
   * @returns The schedule result as an "any" promise
   */
  public async sendLocalNotification(
    id: number,
    title: string,
    body: string,
    delayInSeconds: number = 0,
  ): Promise<any> {
    return await LocalNotifications.schedule({
      notifications: [
        {
          body: body,
          id: id,
          title: title,
          actionTypeId: "OPEN_PRODUCT", //TODO: I'm not sure why we're using OPEN_PRODUCT -
          smallIcon: 'alert',
          schedule: {
            at: new Date(Date.now() + 1000 + Math.abs(delayInSeconds) * 1000),
            repeats: false,
            count: 1,
          },
        },
      ],
    });
  }

  /**
   * Add a listener that is called when the network type changes (Ethernet, WiFi, Cellular, None, Unknown)
   * @param listener A handler for the event that fires when the network type changes.
   * @returns an empty promise.
   */
  // public async addListenerForNetworkStatus(
  //   handler: (connected: boolean, type: NetworkTypeEnum) => void
  // ): Promise<void> {
  //   await Network.addListener("networkStatusChange", (status) => {
  //     handler(
  //       status.connected,
  //       this.networkTypeToEnum(status.connectionType)
  //     );
  //   });
  // }

  /**
   * Remove any listeners currently active with the plugin.
   * @returns an empty promise.
   */
  public async removeAllListenersForNetwork(): Promise<void> {
    await Network.removeAllListeners();
  }

  /**
   * Get the current network type.
   * @returns the current network type (Ethernet, WiFi, Cellular, None, Unknown)
   */
  public async getNetworkType(): Promise<NetworkTypeEnum> {
    const promise = new Promise<NetworkTypeEnum>((resolve, reject) => {
      Network.getStatus()
        .then((status) => {
          resolve(this.networkTypeToEnum(status.connectionType));
        })
        .catch((err) => resolve(NetworkTypeEnum.Unknown));
    });

    return await promise;
  }

  /**
   * Get information from multiple plugins about the device, os, sensors, etc.
   * @param forceCacheRefresh The environment information is cached. If you pass in true, it will reload the info from the plugins.
   * @returns Information about the environment.
   */
  public async getEnvironmentInfo(forceCacheRefresh: boolean = false): Promise<IEnvironmentInformation> {
    // if cached environment info exists, return that (unless a refresh is requested)
    if (!forceCacheRefresh && !!this._cachedEnvironmentInfo) return this._cachedEnvironmentInfo;

    this.deviceInfo = await Device.getInfo();
    //this.appInfo = await this.getAppInfo();

    this._cachedEnvironmentInfo = {
      // using capacitor device plugin
      manufacturer: this.deviceInfo.manufacturer,
      model: this.deviceInfo.model,
      platform: this.deviceInfo.platform,
      operatingSystem: this.deviceInfo.operatingSystem,
      operatingSystemVersion: this.deviceInfo.osVersion,
      webViewVersion: this.deviceInfo.webViewVersion,

      // using capacitor app plugin
      // applicationId: this.appInfo?.id,
      // applicationName: this.appInfo?.name,
      // applicationVersion: this.appInfo?.version,
      // buildVersion: this.appInfo?.build,
    } as IEnvironmentInformation;

    return this._cachedEnvironmentInfo;
  }

  /**
   * Gets the device's sensor/plugin statuses, all in one object.
   * @returns an object with the status of most of the plugins and sensors.
   */
  public async getDeviceStatus(): Promise<IDeviceStatus> {
    const deviceStatus: IDeviceStatus = {};
    if (this.isNativePlatform) {
      if (this.cordovaDiagnostic) {
        try {
          const isWifiEnabled: any = await this.cordovaDiagnostic.isWifiEnabled();
          const isWifiAvailable: any = await this.cordovaDiagnostic.isWifiAvailable();

          deviceStatus.isWifiAvailable = isWifiAvailable === 1 || isWifiAvailable === true;
          deviceStatus.isWifiEnabled = isWifiEnabled === 1 || isWifiEnabled === true;
          deviceStatus.gpsLocationMode = this.locationModeToEnum(await this.cordovaDiagnostic.getLocationMode());
          deviceStatus.isGpsLocationAvailable = await this.cordovaDiagnostic.isGpsLocationAvailable();
          deviceStatus.isGpsLocationEnabled = await this.cordovaDiagnostic.isGpsLocationEnabled();
          deviceStatus.isCameraPresent = await this.cordovaDiagnostic.isCameraPresent();
          deviceStatus.isCameraAvailable = await this.cordovaDiagnostic.isCameraAvailable();
          deviceStatus.isLocationAvailable = await this.cordovaDiagnostic.isLocationAvailable();
          deviceStatus.isLocationEnabled = await this.cordovaDiagnostic.isLocationEnabled();
        } catch (err) { console.error('Cannot access the sensor info using Cordova diagnostic plugin.', err); }
      }
      if (this.cordovaBackgroundMode) {
        try {
          deviceStatus.isBackgroundModeActive = this.cordovaBackgroundMode.isActive();
          deviceStatus.isBackgroundModeEnabled = this.cordovaBackgroundMode.isEnabled();
        } catch (err) { console.error('Cannot access enabled or active using Cordova Background Mode plugin.', err); }
      }
    } else {
      let locationAvailable = false;
      try {
        locationAvailable = await this.webSvc.isGpsLocationAvailable();
      } catch (err) { }
      deviceStatus.gpsLocationMode = locationAvailable ? GpsLocationModeEnum.HighAccuracy : GpsLocationModeEnum.LocationOff;
      deviceStatus.isWifiAvailable =
        deviceStatus.isWifiEnabled = this.webSvc.isBrowserOnline;
      deviceStatus.isGpsLocationAvailable = true;
      deviceStatus.isGpsLocationEnabled = true;
      deviceStatus.isLocationAvailable = true;
      deviceStatus.isLocationEnabled = locationAvailable;
      deviceStatus.isCameraPresent = true;
      deviceStatus.isCameraAvailable = await this.webSvc.isCameraPresent();
    }

    const deviceInfo = await Device.getInfo();
    deviceStatus.memoryUsed = deviceInfo.memUsed;
    deviceStatus.diskFree = deviceInfo.realDiskFree;
    deviceStatus.diskTotal = deviceInfo.realDiskTotal;

    const networkStatus = await Network.getStatus();
    deviceStatus.isNetworkConnected = networkStatus.connected;
    deviceStatus.networkConnectionType = this.networkTypeToEnum(networkStatus.connectionType);

    return deviceStatus;
  }

  /**
   * Activate the barcode scanner with the given prompt text.
   * @param prompt The text to show the user when launching the camera for a barcode scan
   * @returns the result of the scan (in a promise)
   */
  public async activateBarcodeScanner(prompt: string): Promise<BarcodeScannerResult> {
    if (this.isWebPlatform) return Promise.resolve(this.webSvc.simulateBarcodeScanner(prompt));
    // need to disable background mode before calling scan otherwise foreground service fails to create in Android 14.0
    await this.disableBackgroundMode();

    try {
      const { cancelled, format, text } = await this.cordovaBarcodeScanner
        .scan({
          preferFrontCamera: true,
          showFlipCameraButton: true,
          showTorchButton: true,
          prompt: prompt || 'Place a barcode inside the scan area',
          resultDisplayDuration: 0,
        });
        //  re-enable once scan is complete
      await this.enableBackgroundMode();
      return { cancelled, format, text };
    } catch (err) {
      console.error(err);
      if (err === 'Scan is already in progress') {
        this.gtm.push({ event: 'custom_event', custom_event_name: `scan_already_in_progress` });
        alert(this.translate.translate('MESSAGE.scanAlreadyInProgress'));
        window.location.reload();
      }
      return { cancelled: true, format: 'QR_CODE', text: '' };
    }

  }

  /**
   * Determines if the given app is installed and available on the current device.
   * @returns {Promise<boolean>}
   */
  public isGoogleMapsAvailable(): Promise<boolean> {
    if (this.isWebPlatform)
      return Promise.resolve(this.webSvc.isBrowserOnline);

    return new Promise<any>((resolve, reject) => {
      this.cordovaLaunchNavigator
        .isAppAvailable(this.cordovaLaunchNavigator.APP.GOOGLE_MAPS)
        .then((x) => resolve(x))
        .catch((err) => resolve(false));
    });
  }

  /**
   * Registers a function to be called when a change in Location state occurs.
   * @param {Function} handler
   */
  // public addListenerForLocationMode(handler: Function): void {
  //   if (!this.isWebPlatform && this.cordovaDiagnostic) {
  //     try {
  //       this.cordovaDiagnostic?.registerLocationStateChangeHandler(handler);
  //     } catch (err) {
  //       console.log("cannot run the diagnostic plugin.", err);
  //     }
  //   }
  // }

  /**
   * Launches navigator app
   * @param latitude {number} Longitude
   * @param longitude {number} Latitude
   * @param mapsLaunchType {MapTypeEnum} geo or turnbyturn
   * @returns {Promise<any>}
   */
  public async launchMappingApplication(
    latitude: number,
    longitude: number,
    mapsLaunchType: MapTypeEnum,
  ): Promise<any> {
    if (this.isWebPlatform)
      return await this.webSvc.navigateToMaps(
        latitude,
        longitude,
        mapsLaunchType,
      );

    const isGoogleMapsInstalled =
      await this.isGoogleMapsAvailable();

    const options = {
      app: isGoogleMapsInstalled
        ? this.cordovaLaunchNavigator.APP.GOOGLE_MAPS
        : this.cordovaLaunchNavigator.APP.USER_SELECT,
      launchModeGoogleMaps: mapsLaunchType === MapTypeEnum.Geolocation ?
        'geo' : 'turn-by-turn',
      enableDebug: true,
    };
    return this.cordovaLaunchNavigator.navigate([latitude, longitude], options);
  }

  /**
   * Return true if background mode is enabled.
   */
  public get isBackgroundModeEnabled(): boolean {
    if (this.isWebPlatform) return false;

    return this.cordovaBackgroundMode.isEnabled();

  }

  /**
   * Return true if background mode is active.
   */
  public get isBackgroundModeActive(): boolean {
    if (this.isWebPlatform) return false;

    return this.cordovaBackgroundMode.isActive();
  }

  /**
   * Subscribe to the background mode status change event with this.
   * @param eventType the type of event to suibscribe to (Enable, Disable, Activate, Deactivate, Failure)
   * @returns An observable you can subscribe to.
   */
  public subscribeToBackgroundModeEvent(eventType: BackgroundModeEventType): Observable<any> {
    if (this.isWebPlatform) return EMPTY;

    return this.cordovaBackgroundMode.on(
      BackgroundModeEventType[eventType].toLowerCase(),
    );
  }

  /**
   * Disable web view optimizations (in the BackgrounMode plugin). This is critical to make
   * background mode work. The WebView is what ends up getting suspended when in the background for Ionic apps.
   * @returns an empty promise
   */
  public disableBackgroundModeWebViewOptimizations(): Promise<void> {
    if (this.isWebPlatform) return Promise.resolve();
    this.cordovaBackgroundMode.disableWebViewOptimizations();
    return Promise.resolve();
  }

  /**
   * Disable battery optimizations (in the BackgrounMode plugin).
   * @returns an empty promise
   */
  public disableBackgroundModeBatteryOptimizations(): Promise<void> {
    if (this.isWebPlatform) return Promise.resolve();
    this.cordovaBackgroundMode.disableBatteryOptimizations();
    return Promise.resolve();
  }

  /**
   * Enable background mode.
   * @param title the title shown in the notification tray thingy.
   * @param icon the icon shown in the notification tray thingy (for some android versions)
   * @param text the text body shown in the notification tray thingy.
  * @returns the result of enabling the background mode plugin (in a promise)
   */
  public enableBackgroundMode(title?: string, icon?: string, text?: string): Promise<any> {
    if (this.isWebPlatform) return Promise.resolve();

    this.configureBackgroundModeIfNotAlreadyDone(title, icon, text);

    if (!this.cordovaBackgroundMode.isEnabled()) {
      return new Promise((resolve, reject) => {

        try {

          const timeoutHdl = setTimeout(() => {
            if (sub) {
              sub.unsubscribe();
              reject('backgroundmode enable handler timed out after 5 seconds!');
              console.error('backgroundmode enable handler timed out after 5 seconds!');
            }
          }, 5000);

          //let sub: Subscription | undefined = undefined;
          const sub = this.cordovaBackgroundMode.on('enable').subscribe(subVal => {
            if (timeoutHdl) clearTimeout(timeoutHdl);
            if (sub) sub.unsubscribe();
            //this.configureBackgroundModedisableWebViewOptimizations();
            resolve(subVal);
          });

          this.cordovaBackgroundMode.enable();
        } catch (err) {
          reject(err);
        }
      });
    }

    return Promise.resolve();
  }

  /**
   * Disable background mode.
   * @returns the result of disabling the background mode plugin (in a promise)
   */
  public disableBackgroundMode(): Promise<any> {
    if (this.isWebPlatform) return Promise.resolve();

    this.configureBackgroundModeIfNotAlreadyDone();

    if (this.cordovaBackgroundMode.isEnabled()) {
      return new Promise((resolve, reject) => {

        try {
          const timeoutHdl = setTimeout(() => {
            if (sub) {
              sub.unsubscribe();
              reject('backgroundmode disable handler timed out after 5 seconds!');
              console.error('backgroundmode disable handler timed out after 5 seconds!');
            }
          }, 5000);

          //let sub: Subscription | undefined = undefined;
          const sub = this.cordovaBackgroundMode.on('disable').subscribe(subVal => {
            if (timeoutHdl) clearTimeout(timeoutHdl);
            if (sub) sub.unsubscribe();
            resolve(subVal);
          });

          this.cordovaBackgroundMode.disable();
        } catch (err) {
          reject(err);
        }
      });
    }

    return Promise.resolve();
  }

  /**
   * Move the application to the foreground. (background mode plugin only)
   * @returns an empty promise
   */
  public moveToForeground(): Promise<void> {
    if (this.isWebPlatform) return Promise.resolve();

    this.configureBackgroundModeIfNotAlreadyDone();

    return Promise.resolve(this.cordovaBackgroundMode.moveToForeground());
  }

  /**
   * Move the application to the background.
   * @returns an empty promise
   */
  public moveToBackground(): Promise<void> {
    if (this.isWebPlatform) return Promise.resolve();

    this.configureBackgroundModeIfNotAlreadyDone();

    return Promise.resolve(this.cordovaBackgroundMode.moveToBackground());
  }

  /**
   * Unlock the device if it's locked.
   * @returns an empty promise
   */
  public unlock(): Promise<void> {
    if (this.isWebPlatform) return Promise.resolve();

    this.configureBackgroundModeIfNotAlreadyDone();

    return Promise.resolve(this.cordovaBackgroundMode.unlock());
  }

  /**
   * Wake up the device if it's asleep.
   * @returns an empty promise
   */
  public wakeUp(): Promise<void> {
    if (this.isWebPlatform) return Promise.resolve();

    this.configureBackgroundModeIfNotAlreadyDone();

    return Promise.resolve(this.cordovaBackgroundMode.wakeUp());
  }


  /**
   * Calls a combination of cordova plugins to wake up the device and bring it to the foreground.
   * @returns an empty promise
   */
  public tryWakeUpAndBringToFront(): Promise<void> {
    if (this.isWebPlatform) return Promise.resolve();

    // try to use the bring-to-front plugin
    let success = false;
    try {
      const mywin = window as any;
      if (mywin["plugins"] && mywin["plugins"].bringtofront) {
        const x = mywin["plugins"].bringtofront();
        success = true;
      }
    } catch (err) { }

    this.cordovaBackgroundMode.isScreenOff((isOff) => {
      if (isOff) {
        this.cordovaBackgroundMode.wakeUp();

        // replace the handler with no-op?
        this.cordovaBackgroundMode.isScreenOff((isOff) => { });
      }
    });

    this.cordovaBackgroundMode.unlock();
    this.cordovaBackgroundMode.moveToForeground();
    return Promise.resolve();
  }

  /**
   * Acquire a wakelock by calling this.
   * @returns {Promise<any>} returns whatever result the plugin returns, or "NO-OP" if on the web.
   */
  public acquireWakeLock(): Promise<any> {
    if (this.isWebPlatform) return Promise.resolve('NO-OP');

    return new Promise<any>((resolve, reject) => {
      this.cordovaPowerManagement
        .acquire()
        .then((x) => resolve(x))
        .catch((err) => {
          if (('' + err).indexOf("already active") >= 0)
            resolve('ALREADY ACTIVE');
          else {
            console.error(err);
            reject(err);
          }
        });
    });
  }

  /**
   * Release a wakelock by calling this.
   * @returns {Promise<any>} returns whatever result the plugin returns, or "NO-OP" if on the web.
   */
  public releaseWakeLock(): Promise<any> {
    if (this.isWebPlatform) return Promise.resolve('NO-OP');

    return new Promise<any>((resolve, reject) => {
      this.cordovaPowerManagement
        .release()
        .then((x) => resolve(x))
        .catch((err) => {
          if (('' + err).indexOf("No WakeLock") >= 0)
            resolve('ALREADY INACTIVE');
          else {
            console.log(err);
            reject(err);
          }
        });
    });
  }

  /**
   * Returns true if this is the web and not a device.
   */
  public get isWebPlatform(): boolean {
    return !Capacitor.isNativePlatform();
  }

  /**
   * Returns true if this is ios or android (not the web)
   */
  public get isNativePlatform(): boolean {
    return Capacitor.isNativePlatform();
  }

  private get isAndroid(): boolean {
    return Capacitor.getPlatform() === 'android';
  }

  private locationModeToEnum(mode: string): GpsLocationModeEnum {
    switch (mode) {
      case this.cordovaDiagnostic.locationMode.HIGH_ACCURACY:
        return GpsLocationModeEnum.HighAccuracy;
      case this.cordovaDiagnostic.locationMode.BATTERY_SAVING:
        return GpsLocationModeEnum.BatterySaving;
      case this.cordovaDiagnostic.locationMode.DEVICE_ONLY:
        return GpsLocationModeEnum.DeviceOnly;
      case this.cordovaDiagnostic.locationMode.LOCATION_OFF:
        return GpsLocationModeEnum.LocationOff;
      default:
        return GpsLocationModeEnum.Unknown;
    }
  }

  private networkTypeToEnum(connectionType: string): NetworkTypeEnum {
    let type = NetworkTypeEnum.Unknown;
    switch (connectionType) {
      case "wifi":
        type = NetworkTypeEnum.WiFi;
        break;
      case "cellular":
        type = NetworkTypeEnum.Cellular;
        break;
      case "none":
        type = NetworkTypeEnum.None;
        break;
      default:
        type = NetworkTypeEnum.Unknown;
        break;
    }
    return type;
  }

  private permissionStateToEnum(p: string): PermissionStatusEnum {
    switch (p) {
      case "prompt-with-rationale":
        return PermissionStatusEnum.PromptWithReason;
      case "granted":
        return PermissionStatusEnum.Granted;
      case "denied":
        return PermissionStatusEnum.Denied;
      case "prompt":
      default:
        return PermissionStatusEnum.Prompt;
    }
  }

  private configureBackgroundModeIfNotAlreadyDone(title?: string, icon?: string, text?: string) {
    if (!this._isBackgroundModeConfigured) {
      this.cordovaBackgroundMode.configure({
        title: title || environment.appName || 'ParaScope',
        text: text || 'Running in background',
        icon: icon || 'icon',
        resume: true,
        hidden: true,
        bigText: true,
        silent: true,
        ticker: text || 'Running in background',
      });
      this._isBackgroundModeConfigured = true;
    }
  }
}

export interface IEnvironmentInformation {
  manufacturer?: string;
  model?: string;
  platform?: string;
  operatingSystem?: string;
  operatingSystemVersion?: string;
  //uuid?: string;
  webViewVersion?: string;
  applicationId?: string;
  applicationName?: string;
  applicationVersion?: string;
  buildVersion?: string;
}

export type IDeviceStatus = {
  gpsLocationMode?: GpsLocationModeEnum;
  isBackgroundModeEnabled?: boolean;
  isBackgroundModeActive?: boolean;
  isGpsLocationAvailable?: boolean;
  isGpsLocationEnabled?: boolean;
  isLocationAvailable?: boolean;
  isLocationEnabled?: boolean;
  isCameraPresent?: boolean;
  isCameraAvailable?: boolean;
  isWifiEnabled?: boolean;
  isWifiAvailable?: boolean;
  memoryUsed?: number;
  diskFree?: number;
  diskTotal?: number;
  isNetworkConnected?: boolean;
  networkConnectionType?: NetworkTypeEnum;
}

export interface BarcodeScannerResult {
  format:
  | "QR_CODE"
  | "DATA_MATRIX"
  | "UPC_E"
  | "UPC_A"
  | "EAN_8"
  | "EAN_13"
  | "CODE_128"
  | "CODE_39"
  | "CODE_93"
  | "CODABAR"
  | "ITF"
  | "RSS14"
  | "RSS_EXPANDED"
  | "PDF_417"
  | "AZTEC"
  | "MSI";
  text: string;
  cancelled: boolean;
}

export enum GpsLocationModeEnum {
  Unknown,
  HighAccuracy,
  BatterySaving,
  DeviceOnly,
  LocationOff,
}

export enum PermissionStatusEnum {
  Prompt,
  PromptWithReason,
  Denied,
  Granted,
}

export enum NetworkTypeEnum {
  Ethernet,
  WiFi,
  Cellular,
  None,
  Unknown,
}

export enum MapTypeEnum {
  Geolocation,
  TurnByTurnDirections
}

export enum BackgroundModeEventType {
  Enable,
  Disable,
  Activate,
  Deactivate,
  Failure
}
