import { AppData, SESSION_KEY } from "../data";
import { Injectable, inject } from "@angular/core";

import { UserSession } from "../models/user-session.type";

@Injectable({
  providedIn: 'root',
})
export class SessionService {

  private readonly data = inject(AppData);
  private readonly gtm = (<any>window).dataLayer || [];

  loadUserSession(): UserSession {
    const session = this.getSession() || {};
    this.saveSession(session);
    return session;
  }

  private getSession(): UserSession | null {
    const session = sessionStorage.getItem(SESSION_KEY);
    return ((!!session) ? JSON.parse(session) : null);
  }

  public saveSession(session: UserSession | null): void {
    this.gtm.push({
      session_id: session?.sessionId,
      route_id: session?.activatedRouteId,
    });
    this.data.set('userSession', session);
    if (!!session) {
      sessionStorage.setItem(SESSION_KEY, JSON.stringify(session));
    } else {
      sessionStorage.removeItem(SESSION_KEY);
    }
  }

  public async isLoggedIn(): Promise<boolean> {
    const session = this.data.userSession;
    return !!session.pin;
  }

  public async hasActiveRoute(): Promise<boolean> {
    const session = this.data.userSession;
    return !!session.pin && !!session.activatedRouteId;
  }

  public async updateSession(update: Partial<UserSession>): Promise<UserSession> {
    const currentSession = this.data.userSession || {};
    // update the activatedRouteId to null, if a pin is passed and the pin does not match the currently stored pin;
    const updateActivatedRouteId = !!currentSession.pin && !!update.pin && currentSession.pin !== update.pin;
    let updatedSession = { ...currentSession, ...update };
    if (updateActivatedRouteId) updatedSession = { ...updatedSession, activatedRouteId: null };
    this.saveSession(updatedSession);
    return updatedSession;
  }

  public clearRoute() {
    return this.updateSession({ activatedRouteId: null });
  }

  public clearSession() {
    this.updateSession({ activatedRouteId: null, pin: null, sessionId: null });
  }

}
