import { AlertController, ModalController, Platform } from '@ionic/angular';
import { Component, Input, inject } from '@angular/core';

import { AppData } from 'src/app/data';
import { DateFormatPipe } from 'ngx-moment';
import { DeviceService } from '../../services/device.service';
import { DispatcherService } from '../../services/dispatcher.service';
import { FlagStopPerformPage } from '../../pages/flag-stop-perform/flag-stop-perform.page';
import { LocationService } from '../../services/location.service';
import { RouteProviderService } from '../../services/route-provider.service';
import { ScheduledFlagStop } from '../../models/flag-stop';
import { TranslateService } from '../../services/translate.service';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'app-scheduled-flag-stop',
  templateUrl: './scheduled-flag-stop.component.html',
  styleUrls: ['./scheduled-flag-stop.component.scss'],
})
export class ScheduledFlagStopComponent {

  public readonly data = inject(AppData);

  @Input() flagStop: ScheduledFlagStop;
  momentPipe: DateFormatPipe;
  isIos = this.platform.is('ios');

  constructor(
    public alertService: AlertService,
    public deviceService: DeviceService,
    public routeProvider: RouteProviderService,
    private modalController: ModalController,
    private dispatcherService: DispatcherService,
    public alertController: AlertController,
    private locationService: LocationService,
    private translateService: TranslateService,
    private platform: Platform,
  ) {
    this.momentPipe = new DateFormatPipe();
  }

  async startFlagStop() {
    if (this.flagStop.latitude && this.flagStop.longitude) {
      const hasArrived = this.locationService.hasArrived({ latitude: this.flagStop.latitude, longitude: this.flagStop.longitude });
      let confirmedAlert = hasArrived;
      if (!hasArrived) {
        confirmedAlert = await new Promise(async resolve => {
          await this.alertService.presentAlert({
            cssClass: 'alert-wrapperz',
            header: this.translateService.translate('LABEL.confirmArrival'),
            message: this.translateService.translate('LABEL.jobNotInRange')
              + `${this.translateService.translate('LABEL.scheduledStop')} ${this.translateService.translate('LABEL.at')} `
              + `${this.flagStop.location}?`,
            buttons: [{
              text: this.translateService.translate('LABEL.yes'),
              cssClass: 'primary',
              handler: () => { resolve(true); },
            }, {
              text: this.translateService.translate('LABEL.no'),
              cssClass: 'danger',
              handler: () => { resolve(false); },
            }],
          });
        });
      }
      if (!confirmedAlert) { return; }
    }

    const modal = await this.modalController.create({
      component: FlagStopPerformPage,
      componentProps: { scheduledFlagStop: this.flagStop },
      backdropDismiss: false,
      cssClass: 'parascope-full-modal',
    });
    await modal.present();
    await modal.onDidDismiss();
  }

  async quickPerform() {
    const alert = await this.alertController.create({
      header: 'Confirm',
      message: `${this.translateService.translate('LABEL.confirmYouAt')} ${this.flagStop.location}`,
      backdropDismiss: false,
      buttons: [
        {
          text: this.translateService.translate('LABEL.no'),
          role: 'cancel',
          cssClass: 'danger',
          handler: () => {
          },
        }, {
          text: this.translateService.translate('LABEL.yes'),
          cssClass: 'secondary',
          handler: async () => {
            await this.dispatcherService.performFlagStop(this.flagStop);

            // mark this flag stop as performed and update the list
            if (this.flagStop) { this.flagStop.performed = true; }
          },
        },
      ],
    });

    await alert.present();
  }

  previewLocation(e) {
    e.stopPropagation();
    if (!this.flagStop.latitude) {
      // there is no location set, should we alert user?
      return;
    }
    this.locationService.previewLocation(
      {
        latitude: this.flagStop.latitude,
        longitude: this.flagStop.longitude,
      }, this.flagStop.location);
  }

  navigateToFlagStop(e) {
    e.stopPropagation();
    if (!this.flagStop.latitude) {
      // there is no location set, should we alert user?
      return;
    }
    this.locationService.navigateToDestination(
      {
        latitude: this.flagStop.latitude,
        longitude: this.flagStop.longitude,
      });
  }
}
