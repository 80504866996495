import { DateTime, Interval } from 'luxon';
import { Injectable, inject } from '@angular/core';
import { Job, JobPerform, JobType, OnTheWay } from '../models/job';
import { NonServicePeriodActionEnum, NonServicePeriodTypeEnum } from '../models/non-service-period';

import { AppData } from '../data';
import { DispatcherService } from './dispatcher.service';
import { LocationUtilityService } from './location-utility.service';
import { Route } from '../models/route';
import { RouteUpdate } from '../models/route-update';
import { ScheduledEvent } from '../components/job-list/job-list.component';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RouteProviderService {

  private readonly data = inject(AppData);

  constructor(
    private dispatcherService: DispatcherService,
  ) { }

  getNoShowOptionList(job: Job) {
    if (job.supportsDuplicatingNoShow) {
      return this.data.noShowOptions;
    } else {
      return this.data.noShowOptions.filter(x => !x.createsCopyOfRide);
    }
  }

  get canCompleteRoute() {
    const stillWorkToDo =
      (this.data.route.preTrip != null && this.data.route.preTrip.inspectionItems?.length && !this.data.route.preTrip.hasBeenCompleted)
      || (this.data.route.postTrip != null && this.data.route.postTrip.inspectionItems?.length && !this.data.route.postTrip.hasBeenCompleted)
      || (this.data.route.break != null && !this.data.route.break.hasBeenCompleted)
      || (this.data.route.jobs && this.data.route.jobs.length > 0);
    return !stillWorkToDo;
  }

  pickupsRemaining() {
    return this.data.route.jobs?.filter(job => job.jobType === JobType.Pickup).length;
  }

  dropOffsRemaining() {
    return this.data.route.jobs?.filter(job => job.jobType === JobType.DropOff).length;
  }

  get arePassengersOnBoard() {
    return (this.dropOffsRemaining() - this.pickupsRemaining()) > 0;
  }

  setRouteForPreview(route: Route): void {
    this.data.set('route', route);
    this.data.set('currentRouteIsPreview', true);
  }

  async startRoute(routeUpdate: RouteUpdate, inspectionStartedDate: Date = null) {
    this.data.set('route', routeUpdate.route);
    this.data.set('driverIsActivelyReporting', true);
    this.data.set('noShowOptions', routeUpdate.noShowOptions);
    if (this.data.route.preTrip && !this.data.route.preTrip.hasBeenStarted) {
      this.data.set('route', { ...this.data.route, preTrip: { ...this.data.route.preTrip, hasBeenStarted: true }});
      await this.startNonServicePeriod(NonServicePeriodTypeEnum.PreTrip, inspectionStartedDate);
    }
  }

  async reportJobsOnTheWay(onTheWays: OnTheWay[]): Promise<void> {
    await this.dispatcherService.onTheWays(onTheWays);
    const jobUpdates = onTheWays.map(onTheWay => {
      const match = this.data.route.jobs.find(j => j.rideId === onTheWay.rideId && j.jobType === JobType.Pickup);
      if (!match) { return null; }
      return { ...match, estimatedTimeOfArrival: onTheWay.estimatedTimeOfArrival, onTheWayTime: onTheWay.gpsReport.occuranceDate };
    }).filter(job => !!job);
    this.data.setJobs(jobUpdates);
  }

  async performJob(jobPerform: JobPerform) {
    const route = this.data.route;
    const matchingJob = route.jobs.find(job =>
      (    (jobPerform.rideId !== 0 && job.rideId === jobPerform.rideId)
        || (jobPerform.rideId === 0 && job.flagStopId === jobPerform.flagStopId))
      && job.jobType === jobPerform.jobType);

    let index = 0;
    if (matchingJob) {
      matchingJob.performTime = new Date(); // this may be wrong time zone
      index = route.jobs.indexOf(matchingJob, 0);
      if (index > -1) {
        // I think we should just pull it out of the list, but we could also set perform time and show in list conditionally
        route.jobs.splice(index, 1);
      }
    }

    if (jobPerform.jobType === JobType.Pickup) {
      const matchingDropoff = route.jobs.find(x => (
          (jobPerform.rideId !== 0 && x.rideId === jobPerform.rideId)
          || (jobPerform.rideId === 0 && x.flagStopId === jobPerform.flagStopId)
        ) && x.jobType === JobType.DropOff);
      if (matchingDropoff) {
        matchingDropoff.jobEnabled = true;
        if (jobPerform.noShowReason != null) {
          index = route.jobs.indexOf(matchingDropoff, 0);
          if (index > -1) {
            // I think we should just pull it out of the list, but we could also set perform time and show in list conditionally
            route.jobs.splice(index, 1);
          }
        }

        if (matchingJob && matchingJob.riderSignatureRequired && jobPerform.signature) {
          matchingDropoff.riderSignatureRequired = false;
        }


      } else {
        const possibleMatchingDropoff = route.jobs.find(x =>
          (x.rideId === jobPerform.rideId && x.jobType === JobType.DropOff));

        console.warn(`cannot find match for job perform.`, jobPerform, possibleMatchingDropoff, route.jobs);
      }
    }
    this.data.set('route', route);
    await this.dispatcherService.performJob(jobPerform, this.arePassengersOnBoard);
  }

  async startNonServicePeriod(type: NonServicePeriodTypeEnum, inspectionStartedDate: Date = new Date()): Promise<void> {
    await this.dispatcherService.performNonServicePeriod(NonServicePeriodActionEnum.Start, type, inspectionStartedDate);
  }

  async finishNonServicePeriod(type: NonServicePeriodTypeEnum): Promise<void> {
    await this.dispatcherService.performNonServicePeriod(NonServicePeriodActionEnum.End, type);
  }

  public getSimilarJobs({ can, job }: { can: (job: Job) => boolean, job: Job }): ScheduledEvent<Job>[] {
    const { timeTolerance, distanceTolerance } = environment.features;

    return this.data.route.jobs.filter(otherJob => {
      const interval = Interval.fromDateTimes(
        DateTime.fromJSDate(new Date(otherJob.scheduledTime)),
        DateTime.fromJSDate(new Date(job.scheduledTime)),
      );
      const timeDiff = interval?.isValid ? interval.count('minutes') : Infinity;

      const distanceBetween =
        !job.location.coordinate
        || !otherJob.location.coordinate
              ? null
              : LocationUtilityService.euclideanDistanceInMiles(
                  otherJob.location.coordinate.latitude,
                  otherJob.location.coordinate.longitude,
                  job.location.coordinate.latitude,
                  job.location.coordinate.longitude,
                );

             // it's not the job we are already working on
      return job.rideId !== otherJob.rideId
          // otherJob is not a will call
          && !otherJob.willCall
          // job are of the same type (pickup, dropoff)
          && job.jobType === otherJob.jobType
          // has a scheduled time
          && otherJob.scheduledTime
          // we are able to calculate the distance because both are geo-coded
          && distanceBetween != null
          //  we are close enough -- distance
          && distanceBetween <= distanceTolerance
          // we are close enough -- time
          && timeDiff <= timeTolerance
          // we are allowed to perform action on job (ex: canArrive, canArrive...)
          && can(otherJob);
    });
  }
}
