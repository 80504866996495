import { AppData } from 'src/app/data';
import { Component, OnInit } from '@angular/core';
import { GpsReport } from 'src/app/models/gps-report';
import { LocationService } from '../../services/location.service';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-gps-info',
  templateUrl: './gps-info.page.html',
  styleUrls: ['./gps-info.page.scss'],
})
export class GpsInfoPage implements OnInit {

  gpsReport: Observable<GpsReport> = this.data.gpsReport$;
  private readonly gtm = (<any>window).dataLayer || [];

  constructor(
    public data: AppData,
    private locationService: LocationService,
    private modalController: ModalController,
  ) { }

  ngOnInit(): void {
    this.gtm.push({ event: 'custom_event', custom_event_name: 'view_ gps_testing_dialog' });
  }


  async dismiss(): Promise<void> {
    await this.modalController.dismiss();
  }


  async changePace(): Promise<void> {
    this.locationService.changePace(true);
  }

}
