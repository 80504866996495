import { App, AppInfo } from "@capacitor/app";
import { AsyncSubject, ReplaySubject, firstValueFrom } from 'rxjs';
import { LoadingController, Platform } from '@ionic/angular';
import { Pro_SnapshotInfo, SnapshotManagementService } from './plugin/snapshot-management.service';

import { Capacitor } from '@capacitor/core';
import { Injectable } from '@angular/core';
import { LoggingService } from './logging.service';
import { NotificationService } from './notification.service';
import { buildInfo } from '../../config/build-info';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class VersionService {

	// this may go elsewhere but need to display on ui for now
	snapshotInfo: Pro_SnapshotInfo = null;
	snapshotInfo$ = new ReplaySubject<Pro_SnapshotInfo>(1);

	// putting this here since it's a singleton and will persist for life of app instance
	showPlayStoreUpgradeButton = false;
	upgradeMessage = '';
	forceUpdateBeforeLogin = false;

	lastCheckForUpdate: number = 0;

	config = {
		appId: buildInfo.ionicAppId,
		channel: buildInfo.ionicAppChannel,
	};
  private readonly gtm = (<any>window).dataLayer || [];

	readonly initialized$ = new AsyncSubject<boolean>();

	constructor(
		private snapshotService: SnapshotManagementService,
		private platform: Platform,
		private loggingService: LoggingService,
		public notificationService: NotificationService,
		public loadingCtrl: LoadingController,
	) {
		this.setSnapShotInfo()
			.then(() => { this.initialized$.next(true); })
			.catch(err => {
				console.error('error initializing platform', err);
				this.initialized$.next(false);
			}).finally(() => this.initialized$.complete());
	}

	private async setSnapShotInfo() {
		if (!this.isMobilePlatform) { return; }
		await this.platform.ready();
		this.snapshotInfo = await this.snapshotService.getCurrentVersion() || null;
		this.snapshotInfo$.next(this.snapshotInfo);
    this.updateGtmDataLayer(this.snapshotInfo);
		console.info(`Current snapshot version found. `, this.snapshotInfo);
	}

	resetUpgradeVariables() {
		this.showPlayStoreUpgradeButton = false;
		this.upgradeMessage = '';
		this.forceUpdateBeforeLogin = false;
	}

	// this always gets called after we register our device on the server and know what channel it should point to
	setChannel(channel: string) {
		// if we're changing the channel, we should get fresh variables so we can test the new version's validity
		this.resetUpgradeVariables();
		this.config.channel = channel;
	}


	async getAppInfo(): Promise<AppInfo> {
		await firstValueFrom(this.initialized$);
		return this.isMobilePlatform
			? await App.getInfo()
			: { name: '', id: '', build: '0', version: '0' };
	}

	async checkForNewVersion(silent: boolean = false, force: boolean = false) {
		const initialized = await firstValueFrom(this.initialized$);
		if (!initialized) { throw new Error('failed to initialize version service'); }
		const timeSinceLastChecked = new Date().getTime() - this.lastCheckForUpdate;

		//	if it's been more than 10 minutes let's check again
		if (!force && timeSinceLastChecked < 60 * 10 * 1000) { return; }

		this.lastCheckForUpdate = new Date().getTime();
		await this.platform.ready();

		// return if not on device
		if (!this.isMobilePlatform) {
      this.gtm.push({ app_channel: 'sim' });
      if (!silent) this.notificationService.info('Software is up to date');
      return;
    }

		if (!this.config.channel || this.config.channel === 'configurable') {
      console.warn('You cannot update unless the channel has been defined.');
			if (!silent) this.notificationService.info(`You cannot update unless the channel has been defined.`);
			return;
		}

		const loading = await this.loadingCtrl.create({
			spinner: 'crescent',
			message: 'Checking for updates...',
		});
		loading.present();

		try {
			// 	this assumes channel is already correctly set on config
			//	we need to override the channel here because the plugin will append the channel to the app id
			//	we do this last minute and let everything else work exactly the same where the backend tells it what channel to use
			const appOverrideChannel = this.config;
			if (environment.features.overrideChannelName && appOverrideChannel.channel.indexOf(environment.appName) < 0) {
        appOverrideChannel.channel = `${environment.appName}_${appOverrideChannel.channel}`;
			}
			await this.snapshotService.configure(appOverrideChannel);

			const info = await this.snapshotService.getConfiguration();
      console.debug('deploy info', info);

			//	this is disabled locally in capacitor.config.ts and re-enabled on the build server
			//	this is to prevent the app from checking for updates when we're developing locally
			if (info.disabled) {
				console.warn('deploy disabled');
				this.notificationService.info('Live updates are disabled in development mode.');
				loading.dismiss();
				return;
			}

			this.snapshotInfo = (await this.snapshotService.getCurrentVersion()) || null;
			this.snapshotInfo$.next(this.snapshotInfo);
			if (!this.snapshotInfo) {
				const allVersions = await this.snapshotService.getAvailableVersions();
				if (allVersions.length > 0) {
					this.snapshotInfo = allVersions[allVersions.length - 1];
					this.snapshotInfo$.next(this.snapshotInfo);
          this.updateGtmDataLayer(this.snapshotInfo);
				}
			}

			// if there is no snapshot info at this point, that means that this is a fresh APK install that's never done a snapshot update.
			// this block will compare the next snapshot build in the current channel with the baseline, and conditionally bounce out.
			if (!this.snapshotInfo) {
				try {
					const nextUpdate = await this.snapshotService.checkForUpdate();
					const nextBuildId = Number.isNaN(Number(nextUpdate?.buildId)) ? -1 : Number(nextUpdate?.buildId);
					const isOlderThanBaseline = nextBuildId > 0 && nextBuildId <= buildInfo.baselineBuildId;
					this.loggingService.setVersion((nextBuildId || '').toString());

					// the current version is newer than the one we're fetching from appFlow.. abort.
					if (isOlderThanBaseline) {
						console.info(`The baseline version, "${buildInfo.baselineBuildId}", is greater than or equal to the latest appflow version, "${nextBuildId}". Skipping download.`);
						loading.dismiss();
						return;
					} else {
						console.info(`The baseline version, "${buildInfo.baselineBuildId}", is less than the latest appflow version, "${nextBuildId}". Call sync!`);
					}
				} catch (err) {
					console.error(`Unexpected error comparing with baseline buildId (baseline is ${buildInfo.baselineBuildId}).`, err);
					loading.dismiss();
					return;
				}

				loading.dismiss();
			}

			const resp = await this.snapshotService.sync('auto', (progress) => {
				loading.message = `Applying updates ${progress}%. App will restart when finished.`;
			});

			this.loggingService.setVersion(this.snapshotInfo?.buildId);

			if (!this.snapshotInfo || this.snapshotInfo.versionId !== resp.versionId) {
				this.snapshotInfo = resp;
				this.snapshotInfo$.next(this.snapshotInfo);
        this.updateGtmDataLayer(this.snapshotInfo);

				// We found an update, and are in process of redirecting you since you put auto!
				loading.message = `Applying updates. App will restart when finished`;
			} else {
				loading.message = `Software is up to date`;
				if (!silent) this.notificationService.info('Software is up to date');
			}

		} catch (err) {
			console.error('error applying snapshot', err);
      if (!this.config?.channel?.toLocaleLowerCase()?.includes('production')) {
        this.notificationService.warning('There was an error downloading updates. Click check for updates until you don\'t get this error anymore');
      }
		} finally {
			loading.dismiss();
		}

	}

	private get isMobilePlatform() {
		return Capacitor.isNativePlatform();
	}

  private updateGtmDataLayer(value: Pro_SnapshotInfo) {
    this.gtm.push({
      app_channel: this.isMobilePlatform ? value?.channel : 'sim',
      app_flow_build: value?.buildId,
      binary_version: value?.binaryVersionCode,
    });
  }
}
